export function buildContentfullImageUrl(assetUrl, transformations) {
  if (transformations) {
    return 'https:'+ assetUrl + "?" + transformations ;  
  } else {
    return 'https:'+ assetUrl ;  
  }
}

export function buildContentfullImageUrlByHeight(assetUrl, height = 'auto', transformations = 'c_limit', format = 'auto') {
  return 'https:'+ assetUrl + "?h="+ height ;
}

export function buildContentfullImageUrlByWidth(assetUrl, width = 'auto', transformations = 'c_limit', format = 'auto') {
  return 'https:' + assetUrl;
}

export function imageContentfullSrc(assetUrl, transformations) {
  return buildContentfullImageUrl(assetUrl, transformations);
}

export function imageContentfullDpr(assetUrl, transformations = '') {
  if (!transformations) {
    return buildContentfullImageUrl(assetUrl);
  }

  return buildContentfullImageUrl(assetUrl, transformations)
}

export default {
  buildContentfullImageUrl,
  buildContentfullImageUrlByHeight,
  buildContentfullImageUrlByWidth,
  imageContentfullDpr,
  imageContentfullSrc,
};
